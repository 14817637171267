import { NgModule } from '@angular/core';
import { AppointmentService } from './providers/appointment.service';
import { Store, StoreModule } from '@ngrx/store';
import { appointmentReducer } from './store/one-time-appointment/appointment.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AppointmentEffects } from './store/one-time-appointment/appointment.effect';
import { CreateTanComponent } from './components/one-time-appointment(tan)/create-tan-tab/create-tan.component';
import { CreateTanFailedComponent } from './components/one-time-appointment(tan)/create-tan-failed/create-tan-failed.component';
import { CreateTanSuccessfulComponent } from './components/one-time-appointment(tan)/create-tan-successful/create-tan-successful.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '../utils/utils.module';
import { CreateTanService } from './providers/create-tan.service';
import { AppointmentOverviewComponent } from './components/appointment-overview/appointment-overview.component';
import { AppointmentInfoComponent } from './components/appointment-info/appointment-info.component';
import { AppointmentInfoForPatientsComponent } from './components/appointment-info-for-patients/appointment-info-for-patients.component';
import { AppointmentRequestModule } from '../appointment-request/appointment-request.module';
import { HistoryTabComponent } from './components/history-tab/history-tab.component';
import { UpcomingTabComponent } from './components/upcoming-tab/upcoming-tab.component';
import { RequestTabComponent } from './components/request-tab/request-tab.component';
import { RouterModule } from '@angular/router';
import { AppointmentInfoService } from './providers/appointment-info.service';
import { CreateAppointmentComponent } from './components/create-appointment/create-appointment.component';
import { AppointmentTypeModule } from './appointment-type/appointment-type.module';
import { ProductListItemComponent } from './components/consultation-hour/product-list-item/product-list-item.component';
import { TabConsulationHoursComponent } from './components/consultation-hour/tab-consulation-hours/tab-consulation-hours.component';
import { ProductFormComponent } from './components/consultation-hour/product-form/product-form.component';
import { ConsultationHourFormComponent } from './components/consultation-hour/consultation-hour-form/consultation-hour-form.component';
import { DailyTimeframeComponent } from './components/consultation-hour/daily-timeframe/daily-timeframe.component';
import { FormLineComponent } from './components/consultation-hour/consultation-hour-form/form-line/form-line.component';
import { LogAppointmentService } from '../logging/providers/log-appointment.service';
import { InstitutionSettingsModule } from '../institution-settings/institution-settings.module';
import { CreateTanAppointmentComponent } from './components/create-tan-appointment/create-tan-appointment/create-tan-appointment.component';
import { CreateMultiUserTanService } from './providers/create-multi-user-tan.service';
import { MultiUserAppointmentEffects } from './store/appointment/multi-user-appointment.effect';
import { FeatureFlagService } from '../feature-flag/providers/feature-flag.service';
import { MultiUserAppointmentOverviewComponent } from './components/appointment-overview/multi-user-appointment-overview/multi-user-appointment-overview.component';
import { MultiUserAppointmentInfoComponent } from './components/appointment-info/multi-user-appointment-info/multi-user-appointment-info.component';
import {
    CreateTanAppointmentFailComponent
} from './components/create-tan-appointment/create-tan-appointment-fail/create-tan-appointment-fail.component';
import {
    CreateTanAppointmentSuccessComponent
} from './components/create-tan-appointment/create-tan-appointment-success/create-tan-appointment-success.component';
import { MultiUserUpcomingTabComponent } from './components/upcoming-tab/multi-user-upcoming-tab/multi-user-upcoming-tab.component';
import { multiUserAppointmentReducer } from './store/appointment/multi-user-appointment.reducer';
import { MultiUserAppointmentService } from './providers/multi-user-appointment.service';
import { MultiUserHistoryTabComponent } from './components/history-tab/multi-user-history-tab/multi-user-history-tab.component';
import { MultiUserAppointmentInfoForPatientsComponent } from './components/appointment-info-for-patients/multi-user-appointment-info-for-patients/multi-user-appointment-info-for-patients.component';
import { CanceledTabComponent } from './components/canceled-tab/canceled-tab.component';
import { ClipboardModule } from 'ngx-clipboard';
import { TagInputModule } from 'ngx-chips';

@NgModule({
    imports: [
        FormsModule,
        UtilsModule,
        ReactiveFormsModule,
        CommonModule,
        // NgxPermissionsModule.forChild(),
        TranslateModule.forChild(),
        StoreModule.forFeature('appointment', appointmentReducer),
        StoreModule.forFeature('multiUserAppointment', multiUserAppointmentReducer),
        EffectsModule.forFeature([AppointmentEffects, MultiUserAppointmentEffects]),
        ClipboardModule,
        AppointmentRequestModule,
        AppointmentTypeModule,
        RouterModule,
        TagInputModule,
        InstitutionSettingsModule
    ],
    providers: [
        CreateTanService,
        CreateMultiUserTanService,
        AppointmentInfoService,
        AppointmentService,
        MultiUserAppointmentService,
        Store,
        LogAppointmentService,
        FeatureFlagService
    ],
    declarations: [
        CreateTanComponent,
        CreateTanFailedComponent,
        CreateTanSuccessfulComponent,
        CreateTanAppointmentComponent,
        CreateTanAppointmentFailComponent,
        CreateTanAppointmentSuccessComponent,
        AppointmentOverviewComponent,
        MultiUserAppointmentOverviewComponent,
        AppointmentInfoComponent,
        AppointmentInfoForPatientsComponent,
        MultiUserAppointmentInfoComponent,
        HistoryTabComponent,
        UpcomingTabComponent,
        RequestTabComponent,
        CanceledTabComponent,
        CreateAppointmentComponent,
        TabConsulationHoursComponent,
        ProductFormComponent,
        ProductListItemComponent,
        ConsultationHourFormComponent,
        DailyTimeframeComponent,
        FormLineComponent,
        MultiUserUpcomingTabComponent,
        MultiUserHistoryTabComponent,
        MultiUserAppointmentInfoForPatientsComponent
    ],
    exports: [
        AppointmentInfoComponent,
        MultiUserAppointmentInfoComponent,
        AppointmentInfoForPatientsComponent,
        MultiUserAppointmentInfoForPatientsComponent,
        RouterModule
    ]
})
export class AppointmentModule {
    constructor() {

    }
}
